.container-fluid {
  background: linear-gradient(132deg, #203679, #142350) 0 0 / 100% 200%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.frontpage_feature {
  position: static;
  top: 0;
}

.anim_delay1 {
  animation-delay: .5s;
}

.anim_delay2 {
  animation-delay: 1s;
}

.anim_fadein {
  opacity: 0;
  animation: .4s ease-in forwards fp_fadein;
}

.anim_fadein:nth-child(2) {
  animation-delay: .5s;
}

.anim_fadein:nth-child(3) {
  animation-delay: 1.2s;
}

@media (orientation: portrait) {
  .anim_fadein:nth-child(2), .anim_fadein:nth-child(3) {
    animation-delay: 0s;
  }
}

.anim_throb {
  animation: 4s ease-in-out 1s infinite fp_throb;
}

@keyframes fp_throb {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(.9);
  }

  10% {
    transform: scale(1);
  }

  15% {
    transform: scale(.9);
  }

  20% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fp_fadein {
  from {
    opacity: 0;
    position: relative;
    transform: translateY(10vh);
  }

  to {
    opacity: 1;
    position: static;
    transform: translateY(0);
  }
}

.instagram {
  width: 480px;
}

/*# sourceMappingURL=featureanim.css.map */
