.container-fluid{
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;

  background: linear-gradient(132deg, #203679, #142350);
  background-size: 100% 200%;
  /* animation: Gradient 15s ease infinite; */
  width: 100%;
  padding:0;
  margin:0px;
}
/* ============= Animation background ========= */

.frontpage_feature {
  top: 0;
  position: static;
}

.anim_delay1 {
  animation-delay: 0.5s;
}
.anim_delay2 {
  animation-delay: 1.0s;
}

.anim_fadein {
  animation: 0.4s ease-in fp_fadein forwards;
  opacity: 0%;
}
.anim_fadein:nth-child(2) {
  animation-delay: 0.5s;
}
.anim_fadein:nth-child(3) {
  animation-delay: 1.2s;
}
@media(orientation: portrait) {
  .anim_fadein:nth-child(2) {
    animation-delay: 0.0s;
  }
  .anim_fadein:nth-child(3) {
    animation-delay: 0.0s;
  }
}

.anim_throb {
  animation: 4.0s ease-in-out fp_throb infinite;
  animation-delay: 1.0s;
}

@keyframes fp_throb {
  0% {
    transform: scale(1.0);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(1.0);
  }
  15% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes fp_fadein {
  from {
    opacity: 0%;
    position: relative;
    transform: translateY(10vh);
  }
  to {
    opacity: 100%;
    transform: translateY(0);
    position: static;
  }
}

/*Instagram*/

.instagram {
  width: 480px;
}